export async function parseResponse(requestPromise) {
  const response = await requestPromise;
  return response.data;
  // try {
  //   const response = await requestPromise;
  //   return response.data;
  // } catch (ex) {
  //   // console.error(ex);
  //   return null;
  // }
}

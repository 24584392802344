import { axiosInstanceV1, getReqConfig } from './axiosInstance';
import { parseResponse } from './helper';
import LocalesEnum from '../enums/locales.enum';

const BASE = '/Payment';

export async function getOperators_req(
  cookies = {},
  locale = LocalesEnum.DEFAULT
) {
  return parseResponse(
    axiosInstanceV1.get(`${locale}/Operators`, getReqConfig(cookies))
  );
}

export async function deposit_req(operatorID, amount) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/Deposit`,
      {
        operatorID,
        amount,
      },
      getReqConfig({})
    )
  );
}

export async function withdraw_req(operatorID, amount) {
  return parseResponse(
    axiosInstanceV1.post(
      `${BASE}/Withdraw`,
      {
        operatorID,
        amount,
      },
      getReqConfig({})
    )
  );
}

export async function getDepositTexts_req(
  cookies = {},
  locale = LocalesEnum.DEFAULT
) {
  return parseResponse(
    axiosInstanceV1.get(`${locale}/DepositHeader`, getReqConfig(cookies))
  );
}

export async function getTax_req(cookies = {}, amount) {
  return parseResponse(
    axiosInstanceV1.get(`/GetTax?Amount=${amount}`, getReqConfig(cookies))
  );
}

export async function getWithdrawText_req(
  cookies = {},
  id = 1,
  locale = LocalesEnum.DEFAULT
) {
  // console.log(`/${locale}/Text/${id}`);
  return parseResponse(
    axiosInstanceV1.get(`/${locale}/Text/${id}`, getReqConfig(cookies))
  );
}

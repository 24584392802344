const ModalsEnum = {
  FREE_SPINS: 0,
  FIRST_DEPOSIT: 1,
  NEW_TOURNAMENTS: 2,
  WHEEL: 3,
  CYBER: 4,
  INDEPENDENCE: 5,
  OFFER: 6,
  TOURNAMENT: 7,
  LUCKY_WHEEL: 8,
  REGISTER: 9,
};

Object.freeze(ModalsEnum);

export default ModalsEnum;
